import { Component, HostListener, Inject, LOCALE_ID, Renderer2 } from '@angular/core';
import { ConfigService } from '../@vex/config/config.service';
import { Settings } from 'luxon';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { NavigationService } from '../@vex/services/navigation.service';
import { LayoutService } from '../@vex/services/layout.service';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ActivatedRoute } from '@angular/router';

import { SplashScreenService } from '../@vex/services/splash-screen.service';
import { VexConfigName } from '../@vex/config/config-name.model';
import { ColorSchemeName } from '../@vex/config/colorSchemeName';
import { MatIconRegistry, SafeResourceUrlWithIconOptions } from '@angular/material/icon';
import { ColorVariable, colorVariables } from '../@vex/components/config-panel/color-variables';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  //Clearing the localStorage when the browser closes
  @HostListener("window:beforeunload",["$event"])
  clearLocalStorage(event){
    // localStorage.clear()
  }

  constructor(private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private readonly matIconRegistry: MatIconRegistry,
              private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */

     this.configService.updateConfig({
      sidenav:{
        title: 'ABL Gold',
        showCollapsePin: false
      },
      footer:{
        visible: false
      }
     });

    /**
     * Config Related Subscriptions
     * You can remove this if you don't need the functionality of being able to enable specific configs with queryParams
     * Example: example.com/?layout=apollo&style=default
     */

    //This is set up for changing the configurations

    this.configService.setConfig('vex-layout-hermes' as VexConfigName)

    //Setup ends

    this.route.queryParamMap.subscribe(queryParamMap => {
      if (queryParamMap.has('layout')) {
        this.configService.setConfig(queryParamMap.get('layout') as VexConfigName);
      }

      if (queryParamMap.has('style')) {
        this.configService.updateConfig({
          style: {
            colorScheme: queryParamMap.get('style') as ColorSchemeName
          }
        });
      }

      if (queryParamMap.has('primaryColor')) {
        const color: ColorVariable = colorVariables[queryParamMap.get('primaryColor')];

        if (color) {
          this.configService.updateConfig({
            style: {
              colors: {
                primary: color
              }
            }
          });
        }
      }

      if (queryParamMap.has('rtl')) {
        this.configService.updateConfig({
          direction: coerceBooleanProperty(queryParamMap.get('rtl')) ? 'rtl' : 'ltr'
        });
      }
    });

    /**
     * Add your own routes here
     */
    this.navigationService.items = [
      {
        type: 'link',
        label: 'Dashboard',
        route: 'dashboard',
        routerLinkActiveOptions: { exact: true }
      },
      {
        type: 'subheading',
        label: 'Setting',
        children: [
          {
            type: 'link',
            label: 'Attribute Groups',
            route: 'attribute-group',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Tax',
            route: 'tax',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Tax Classification',
            route: 'tax-classification',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Invoice Setting',
            route: 'invoice-setting',
            routerLinkActiveOptions: { exact: false }
          },
          
          {
            type: 'link',
            label: 'Unit Measurement',
            route: 'unit-measurement',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Tax Commodity',
            route: 'tax-commodity',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Making Charges',
            route: 'making-charges',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'User',
            route: 'user',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Filter Group',
            route: 'filter-group',
            routerLinkActiveOptions: { exact: false }
          },
          
          {
            type: 'link',
            label: 'Transaction Rule',
            route: 'transaction-rules',
            routerLinkActiveOptions: { exact: false }
          },

          {
            type: 'link',
            label: 'Website Status',
            route: 'websitestatus',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Deductuion',
            route: 'deduction',
            routerLinkActiveOptions: { exact: false }
          },
        ]
      },
      {
        type: 'subheading',
        label: 'Master',
        children: [
          {
            type: 'link',
            label: 'Purity Master',
            route: 'purity-master',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Banner Master',
            route: 'banner-master',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'FAQ Master',
            route: 'faq',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Supplier Master',
            route: 'supplier',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Manufacturer Master',
            route: 'manufacturer-master',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Margin Master',
            route: 'margin-master',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Unit Master',
            route: 'unit-master',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Role Master',
            route: 'role',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Category Master',
            route: 'category',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Stone Master',
            route: 'stone-master',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Reason Master',
            route: 'reasonmaster',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'TDS Master',
            route: 'tds-master',
            routerLinkActiveOptions: { exact: false }
          },
          
        ]
      },
      {
        type: 'subheading',
        label: 'Product',
        children: [
          {
            type: 'link',
            label: 'Product',
            route: 'product',
            routerLinkActiveOptions: { exact: false }
          },
          // {
          //   type: 'link',
          //   label: 'Product Images',
          //   route: 'product-images',
          //   routerLinkActiveOptions: { exact: false }
          // },
          {
            type: 'link',
            label: 'Product Images',
            route: 'productimgs',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Discount Sale',
            route: 'discountsales',
            routerLinkActiveOptions: { exact: false }
          },
          {
            type: 'link',
            label: 'Inventory Control',
            route: 'inventorycontrol',
            routerLinkActiveOptions: { exact: false }
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Customer',
        children: [
          {
            type:'link',
            label: 'Customer Group',
            route: 'customer-group',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Customer Type',
            route: 'customer-type',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Customer Master',
            route: 'customer-master',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Customer Bank',
            route: 'customer-bank',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Transfer Request',
            route: 'transfer-request',
            routerLinkActiveOptions: {exact: false}
          }
        ]
      },
      {
        type: 'subheading',
        label: 'Distributor',
        children:[
          // {
          //   type:'link',
          //   label: 'Agent commission',
          //   route: 'agent-commission',
          //   routerLinkActiveOptions: {exact: false}
          // },

          {
            type:'link',
            label: 'Commission',
            route: 'commission',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label:'Distributor Master',
            route: 'agent-master',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label:'Distributor Bank',
            route: 'agent-bank',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label:'Distributor Payout',
            route: 'agent-payout',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Distributor Transfer Request',
            route: 'agent-transfer-request',
            routerLinkActiveOptions: {exact: false}
          }
        ]
      },

      {
        type: 'subheading',
        label: 'Reports',
        children:[
          // {
          //   type:'link',
          //   label: 'Test',
          //   route: 'test',
          //   routerLinkActiveOptions: {exact: false}
          // },
          {
            type:'link',
            label: 'Orders',
            route: 'invoicelist',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Sell',
            route: 'selllist',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Inventory',
            route: 'inventorylist',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Item Sales',
            route: 'itemsales',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Orders Table',
            route: 'Orderlist',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Transaction Report',
            route: 'transaction',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Review Table',
            route: 'review',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Contact List',
            route: 'contactlist',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Distributor Payout',
            route: 'agentpayout',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Partner Commission',
            route: 'partnercommissionreport',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Partner Commission Ledger',
            route: 'partnercommissionledger',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Commission',
            route: 'commissionreport',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Commission Ledger',
            route: 'commissionledger',
            routerLinkActiveOptions: {exact: false}
          },
           {
            type:'link',
            label: 'Abandoned Jewellers Cart',
            route: 'abandonedcart',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Abandoned Digital Cart',
            route: 'abandoneddigicart',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'GST',
            route: 'invoicegst',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Supplier Ledger',
            route: 'invoicedetail',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Making Report',
            route: 'makingreport',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Sale Margin',
            route: 'marginreport',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Purchase Margin',
            route: 'purchasemargin',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Gold-Ledger',
            route: 'goldledger',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Amount-Ledger',
            route: 'amountledger',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Gift Report',
            route: 'giftlist',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Log Report',
            route: 'eventreport',
            routerLinkActiveOptions: {exact: false}
          },



        ]
      },
      {
        type: 'subheading',
        label: 'Partner',
        children:[
          {
            type:'link',
            label: 'Partner Master',
            route: 'partner',
            routerLinkActiveOptions: {exact: false}
          },
          {
            type:'link',
            label: 'Partner Bank',
            route: 'partner-bank',
            routerLinkActiveOptions: {exact: false}
          }

        ]
      }
    ];
  }
}
