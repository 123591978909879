import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './auth/auth.guard';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';

const routes: Routes = [
  {path:'login',loadChildren:()=> import('./pages/auth/login/login.module').then(mod=>mod.LoginModule)},
  {path:'print',loadChildren: ()=> import('./pages/reportss/invoicelist/invoicelist-print/invoicelist-print.module').then(mod=> mod.InvoicelistPrintModule)},
  {path:'sellprint',loadChildren: ()=> import('./pages/reportss/sell/sell-print/sell-print.module').then(mod=> mod.SellPrintModule)},
  {
    path: '', canActivate:[AuthGuard] , component: CustomLayoutComponent, children: [
      {path:'agreement',loadChildren: ()=> import('./pages/agent-master/agreementt/agreementt.module').then(mod=> mod.AgreementtModule)},
      {path:'test',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/test/test.module').then(mod=>mod.TestModule)},
      {path:'dashboard',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/dashboard/dashboard.module').then(mod=>mod.DashboardModule)},
      {path:'attribute-group',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/attributes-group/attributes-group.module').then(mod=>mod.AttributesGroupModule)},
      {path:'tax',canActivate:[AuthGuard], loadChildren: () => import('./pages/tax/tax.module').then(mod=>mod.TaxModule)},
      {path:'tax-classification', canActivate:[AuthGuard], loadChildren: () => import('./pages/tax-classification/tax-classification.module').then(mod=>mod.TaxClassificationModule)},
      {path:'invoice-setting', canActivate:[AuthGuard], loadChildren: () => import('./pages/invoice-setting/invoice-setting.module').then(mod=>mod.InvoiceSettingModule)},
      {path:'manufacturer-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/manufacturer-master/manufacturer-master.module').then(mod=>mod.ManufacturerMasterModule)},
      {path:'category', canActivate:[AuthGuard], loadChildren: () => import('./pages/category/category.module').then(mod=>mod.CategoryModule)},
      {path:'role', canActivate:[AuthGuard], loadChildren: () => import('./pages/role/role.module').then(mod=>mod.RoleModule)},
      {path:'unit-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/unit-master/unit-master.module').then(mod=>mod.UnitMasterModule)},
      {path:'unit-measurement', canActivate:[AuthGuard], loadChildren: () => import('./pages/unit-measurement/unit-measurement.module').then(mod=>mod.UnitMeasurementModule)},
      {path:'user', canActivate:[AuthGuard], loadChildren: () => import('./pages/user/user.module').then(mod=>mod.UserModule)},
      {path:'tax-commodity', canActivate:[AuthGuard], loadChildren: () => import('./pages/tax-commodity/tax-commodity.module').then(mod=>mod.TaxCommodityModule)},
      {path:'making-charges', canActivate:[AuthGuard], loadChildren: () => import('./pages/making-charge/making-charge.module').then(mod=>mod.MakingChargeModule)},
      {path:'margin-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/margin-master/margin-master.module').then(mod=>mod.MarginMasterModule)},
      {path:'product', canActivate:[AuthGuard], loadChildren: () => import('./pages/product/product.module').then(mod=>mod.ProductModule)},
      {path:'product-form', canActivate:[AuthGuard], loadChildren: () => import('./pages/product-form/product-form.module').then(mod=>mod.ProductFormModule)},
      {path:'customer-group', canActivate:[AuthGuard], loadChildren: () => import('./pages/customer-group/customer-group.module').then(mod=>mod.CustomerGroupModule)},
      {path:'customer-type', canActivate:[AuthGuard], loadChildren: () => import('./pages/customer-type/customer-type.module').then(mod=>mod.CustomerTypeModule)},
      {path:'faq', canActivate:[AuthGuard], loadChildren: () => import('./pages/faq/faq.module').then(mod=>mod.FaqModule)},
      {path:'customer-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/customer-master/customer-master.module').then(mod=>mod.CustomerMasterModule)},
      {path:'commission-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/commission-master/commission-master.module').then(mod=>mod.CommissionMasterModule)},
      {path:'agent-commission', canActivate:[AuthGuard], loadChildren: () => import('./pages/agent-commission/agent-commission.module').then(mod=>mod.AgentCommissionModule)},
      {path:'commissionreport', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/newcommision/newcommision.module').then(mod=>mod.NewcommisionModule)},
      {path:'partnercommissionreport', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/partnercommission/partnercommission.module').then(mod=>mod.PartnercommissionModule)},
      {path:'agent-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/agent-master/agent-master.module').then(mod=>mod.AgentMasterModule)},
      {path:'profile', canActivate:[AuthGuard], loadChildren: () => import('./pages/social/social.module').then(mod=>mod.SocialModule)},
      {path:'banner-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/banner-master/banner-master.module').then(mod=>mod.BannerMasterModule)},
      {path:'customer-bank', canActivate:[AuthGuard], loadChildren: () => import('./pages/customer-bank/customer-bank.module').then(mod=>mod.CustomerBankModule)},
      {path:'agent-bank', canActivate:[AuthGuard], loadChildren: () => import('./pages/agent-bank/agent-bank.module').then(mod=>mod.AgentBankModule)},
      {path:'review', canActivate:[AuthGuard], loadChildren: () => import('./pages/reviewtable/reviewtable.module').then(mod=>mod.ReviewtableModule)},
      {path:'productimgs', canActivate:[AuthGuard], loadChildren: () => import('./pages/productimgs/productimgs.module').then(mod=>mod.ProductimgsModule)},
      {path:'productimgsCU', canActivate:[AuthGuard], loadChildren: () => import('./pages/productimgs/productimgs-create-update/productimgs-create-update.module').then(mod=>mod.ProductimgsCreateUpdateModule)},
      {path:'product-images', canActivate:[AuthGuard], loadChildren: () => import('./pages/product-images/product-images.module').then(mod=>mod.ProductImagesModule)},
      {path:'transaction-rules', canActivate:[AuthGuard], loadChildren: () => import('./pages/transaction-rules/transaction-rules.module').then(mod=>mod.TransactionRulesModule)},
      {path:'supplier', canActivate:[AuthGuard], loadChildren: () => import('./pages/supplier/supplier.module').then(mod=>mod.SupplierModule)},
      {path:'invoicelist', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/invoicelist/invoicelist.module').then(mod=>mod.InvoicelistModule)},
      {path:'contactlist', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/contactlist/contactlist.module').then(mod=>mod.ContactlistModule)},
      {path:'agentcommission', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/agent-commission/agent-commission.module').then(mod=>mod.AgentCommissionModule)},
      {path:'inventorylist', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/inventory/inventory.module').then(mod=>mod.InventoryModule)},
      {path:'inventorylistinfo',loadChildren: ()=> import('./pages/reportss/inventory/inventory-info/inventory-info.module').then(mod=> mod.InventoryInfoModule)},
      {path:'selllist', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/sell/sell.module').then(mod=>mod.SellModule)},
      {path:'agentpayout', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/agent-payout/agent-payout.module').then(mod=>mod.AgentPayoutModule)},
      {path:'itemsales', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/itemsales/itemsales.module').then(mod=>mod.ItemsalesModule)},
      {path:'transaction', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/transactionrepost/transactionrepost.module').then(mod=>mod.TransactionrepostModule)},
      {path:'purity-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/purity-master/purity-master.module').then(mod=>mod.PurityMasterModule)},
      {path:'transfer-request', canActivate:[AuthGuard], loadChildren: () => import('./pages/transfer-request/transfer-request.module').then(mod=>mod.TransferRequestModule)},
      {path:'agent-transfer-request', canActivate:[AuthGuard], loadChildren: () => import('./pages/agent-transfer-request/agent-transfer-request.module').then(mod=>mod.AgentTransferRequestModule)},
      {path:'filter', canActivate:[AuthGuard], loadChildren: () => import('./pages/filter/filter.module').then(mod=>mod.FilterModule)},
      {path:'filter-group', canActivate:[AuthGuard], loadChildren: () => import('./pages/filter-group/filter-group.module').then(mod=>mod.FilterGroupModule)},
      {path:'stone-master', canActivate:[AuthGuard], loadChildren: () => import('./pages/stone-master/stone-master.module').then(mod=>mod.StoneMasterModule)},
      {path:'Orderlist', canActivate:[AuthGuard], loadChildren: () => import('./pages/reportss/orderstable/orderstable.module').then(mod=>mod.OrderstableModule)},
      {path:'discountsales', canActivate:[AuthGuard], loadChildren: () => import('./pages/discount-sales/discount-sales.module').then(mod=>mod.DiscountSalesModule)},
      {path:'discountsales-form', canActivate:[AuthGuard], loadChildren: () => import('./pages/discount-sales/discount-sales-create-update/discount-sales-create-update.module').then(mod=>mod.DiscountSalesCreateUpdateModule)},
      {path:'reasonmaster', canActivate:[AuthGuard], loadChildren: () => import('./pages/reason-master/reason-master.module').then(mod=>mod.ReasonMasterModule)},
      {path:'inventorycontrol', canActivate:[AuthGuard], loadChildren: () => import('./pages/inventory-control/inventory-control.module').then(mod=>mod.InventoryControlModule)},
      {path:'notification-list', canActivate:[AuthGuard], loadChildren: () => import('./pages/notification-list/notification-list.module').then(mod=>mod.NotificationListModule)},
      {path:'tds-master',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/tds-master/tds-master.module').then(mod=>mod.TdsMasterModule)},
      {path:'agent-payout',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/agent-payout/agent-payout.module').then(mod=>mod.AgentPayoutModule)},
      {path:'abandonedcart',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/abandonedcart1/abandonedcart1.module').then(mod=>mod.Abandonedcart1Module)},
      {path:'abandoneddigicart',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/abandoneddigicart/abandoneddigicart.module').then(mod=>mod.AbandoneddigiCartModule)},
      {path:'invoicegst',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/invoicegst/invoicegst.module').then(mod=>mod.InvoiceGstModule)},
      {path:'invoicedetail',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/invoicedetail/invoicedetail.module').then(mod=>mod.InvoiceDetailListModule)},
      {path:'makingreport',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/makingreport/makingreport.module').then(mod=>mod.MakingReportModule)},
      {path:'marginreport',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/marginreport/marginreport.module').then(mod=>mod.MarginReportModule)},
      {path:'purchasemargin',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/purchasemargin/purchasemargin.module').then(mod=>mod.purchasemarginModule)},
      {path:'goldledger',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/goldledger/goldledger.module').then(mod=>mod.goldledgerModule)},
      {path:'amountledger',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/amountledger/amountledger.module').then(mod=>mod.amountledgerModule)},
      {path:'giftlist',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/giftlist/giftlist.module').then(mod=>mod.GiftreportModule)},
      {path:'eventreport',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/eventreport/eventreport.module').then(mod=>mod.EventreportModule)},     
      {path:'commissionledger',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/commissionledger/commissionledger.module').then(mod=>mod.CommissionledgerModule)},
      {path:'partnercommissionledger',canActivate:[AuthGuard], loadChildren: ()=> import('./pages/reportss/partnercommissionledger/partnercommissionledger.module').then(mod=>mod.PartnerCommissionledgerModule)},
      {path:'websitestatus',canActivate:[AuthGuard], loadChildren: () => import('./pages/websitestatus/websitestatus.module').then(mod=>mod.WebsitestatusModule)},
      {path:'deduction',canActivate:[AuthGuard], loadChildren: () => import('./pages/deduction/deduction.module').then(mod=>mod.DeductionModule)},
      {path:'commission', canActivate:[AuthGuard], loadChildren: () => import('./pages/commission/commission.module').then(mod=>mod.CommissionModule)},
      {path:'partner', canActivate:[AuthGuard], loadChildren: () => import('./pages/partner/partner.module').then(mod=>mod.PartnerModule)},
      {path:'partner-bank', canActivate:[AuthGuard], loadChildren: () => import('./pages/partner-bank/partner-bank.module').then(mod=>mod.PartnerBankModule)},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
  
}
