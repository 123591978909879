import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core"
import { Router } from "@angular/router";
import { Observable, tap } from "rxjs";

@Injectable()

export class AuthInterceptor implements HttpInterceptor{
    
    constructor(private router: Router){
        
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if(sessionStorage.getItem('token') == null){
            return next.handle(req.clone());
        }else{
            if(req.url == 'https://sandbox.veri5digital.com/service/api/1.0/verifyUserIdDoc'){
                return next.handle(req.clone());
            }else if(req.url == 'https://sandbox.veri5digital.com/okyc/api/v1.0/getCaptcha'){
                return next.handle(req.clone());
            }else if(req.url == 'https://sandbox.veri5digital.com/okyc/api/v1.0/enterAadhaar'){
                return next.handle(req.clone());
            }else if(req.url == 'https://sandbox.veri5digital.com/okyc/api/v1.0/enterOtp'){
                return next.handle(req.clone());
            }else{

                const cloneReq = req.clone({
                    headers: req.headers.set('Authorization','Bearer ' + sessionStorage.getItem('token'))
                });
                return next.handle(cloneReq).pipe(
                    tap(
                        succ => {},
                        err => {
                            if(err.status == 401){
                                sessionStorage.removeItem('token');
                                this.router.navigateByUrl('login')
                            }
                        }
                    )
                )
            }
            
        }
    }
    
}