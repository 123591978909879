<div class="footer flex">
  <div class="flex-auto flex items-center container">
    <a class="action flex-none"
       color="primary"
       href="#"
       id="get-vex"
       mat-flat-button>
      <mat-icon class="icon-sm ltr:mr-2 rtl:ml-2" svgIcon="mat:copyright"></mat-icon>
      <span>Agrawal Bullion Ltd</span>
    </a>
    <!-- <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      Powered By Farsoft Infotech Pvt Ltd
    </div> -->
  </div>
</div>
