import { Component, OnInit } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../../utils/track-by';
import {Notifications} from '../interfaces/notifications.model';
import { NotificationsService } from 'src/@vex/services/notifications.service';
import { Router } from '@angular/router';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  public notifications: any[] = [];

  trackById = trackById;

  constructor(private cusservice:NotificationsService ,private router: Router) {
  //  alert("kih")
    this.cusservice.get().subscribe(res =>{

      let arr : any[] = res as Array<any>
      arr.forEach(e => {
        let sadsad : any = {
                id: e.notification_Id,
      label: e.notification_Title,
      des:e.notification_Description,
      icon: 'mat:shopping_basket',
      colorClass: 'text-primary',
      datetime: e.notification_DateTime
        }
       // console.log(sadsad)
        this.notifications.push(sadsad);
      });

    },err => {
      alert('Error while retreiving data')
      console.error(err)
    })

  }

  ngOnInit() {
  }
  update(id){
//alert(id)
this.cusservice.put(id).subscribe(res => {
  location.reload();
 },err=>{
  if(err.status == 401){
    this.router.navigateByUrl('login')
  }else{
    alert(err.status +': Error while updating the data, please try again later')
  }
 })
  }
  seeall(){
    this.router.navigateByUrl('notification-list')
   // this.router.navigate(['product-form'],{ queryParams: {product_Id:product.product_Id}})
  }

}
