import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Login } from '../interface/login.model';
import { User } from '../pages/user/interface/user.model';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient,private serviceurl:SharedService) { }

  baseUrl: string =this.serviceurl.Backend+ 'Auth/'

  isAllowed(){
    return this.http.get(this.baseUrl + 'isPermitted/', {responseType: "text"})
  }

  login(data: Login): Observable<string>{
    return this.http.post<string>(this.baseUrl + 'Login', data)
  }

  register(data: User): Observable<any>{
    return this.http.post<any>(this.baseUrl + 'Register',data)
  }

  put(id:number, data: User): Observable<any>{
    return this.http.put<any>(this.baseUrl + 'Register/'+ id,data)
  }

  get():Observable<User[]>{
    return this.http.get<User[]>(this.baseUrl + 'GetAll')
  }

  getProfile(): Observable<User>{
    return this.http.get<User>(this.baseUrl + 'GetProfile/')
  }
}
