import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  // Backend: string = 'https://localhost:59469/PVO203/';
  // frontend: string = 'https://localhost:59469/IUX846/';
  // api: string = 'https://localhost:59469/';
  // web:string='http://beta.farsoft.in:3001/'
  // website = "http://localhost:4200/"; 


  //  Backend: string = 'http://beta.farsoft.in/ablapi/PVO203/'
  //  frontend: string = 'http://beta.farsoft.in/ablapi/IUX846/'
  //  api: string = 'http://beta.farsoft.in/ablapi/'
  //  web:string='http://beta.farsoft.in:3001/'
  //  website:string='http://beta.farsoft.in/abladmin/'


  // Backend: string = 'http://farsoftserver/ablapi/PVO203/'
  // frontend: string = 'http://farsoftserver/ablapi/IUX846/'
  // api: string = 'http://farsoftserver/ablapi/'

  Backend: string = 'https://api.hunnarjewels.in/PVO203/'
  frontend: string = 'https://api.hunnarjewels.in/IUX846/'
  api: string = 'https://api.hunnarjewels.in/'
  web:string='http://hunnarjewels.in/'
  website:string='https://admin.hunnarjewels.in/'
 // website = "http://localhost:4200/"; 
}
