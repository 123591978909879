import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SharedService } from './shared.service';

@Injectable({
  providedIn: 'root'
})
export class LivePriceService {

  constructor(private http: HttpClient,private serviceurl:SharedService) { }
  baseUrl: string = this.serviceurl.frontend + 'GoldPrice/'

  getGoldPrice() : Observable<any> {
    return this.http.get(this.baseUrl + 'GetGoldPriceNew', { responseType: "text"});
  }
  
}
