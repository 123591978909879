import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {SharedService} from '../../app/service/shared.service'
import {Notifications} from '../layout/toolbar/toolbar-notifications/interfaces/notifications.model'

@Injectable({
    providedIn: 'root'
  })

  export class NotificationsService {

    constructor(private http: HttpClient,private serviceurl:SharedService) { }
  
    baseUrl: string = this.serviceurl.Backend+'NotificationAlert/'
  
  
    get(): Observable<any[]>{
      return this.http.get<any[]>(this.baseUrl)
    }

    getAll(): Observable<any[]>{
      return this.http.get<any[]>(this.baseUrl+'GetNotificationsAll')
    }

    put(id:number): Observable<any>{
      return this.http.put<any>(this.baseUrl +'updatesingle/' + id,null)
    }
  
   
  }
  